<div class="banner">
  <h1 #title [class.title-focus]="true" [tabindex]="mobile ? 0 : -1">Timezone Database Explorer</h1>
  <h2 [style.visibility]="latestTzVersion ? 'visible' : 'hidden'"
        class="sub-header">Latest tz database version: {{latestTzVersion}}
    <i class="fas fa-info-circle fa-2x info-icon info-icon-adjust"
        [class.disabled]="!notes[latestTzVersion]"
        (click)="releaseNote = latestTzVersion"></i>
  </h2>
</div>
<p-tabView class="tab-view" [(activeIndex)]="tabIndex">
  <p-tabPanel [header]="'Current Time/Time Conv' + (window.innerWidth < 620 ? '.' : 'ersions')"
      [class.tze-tab-selected]="tabIndex === CLOCKS">
    <tze-clocks></tze-clocks>
  </p-tabPanel>
  <p-tabPanel [header]="(window.innerWidth < 620 ? 'TZ' : 'Timezone') + ' History'" [class.tze-tab-selected]="tabIndex === HISTORY">
    <tze-zone-history [hideTransitions]="tabIndex !== HISTORY"></tze-zone-history>
  </p-tabPanel>
  <p-tabPanel header="Downloads" [class.tze-tab-selected]="tabIndex === DOWNLOADS">
    <tze-downloads></tze-downloads>
  </p-tabPanel>
  <p-tabPanel header="Code" [class.tze-tab-selected]="tabIndex === CODE">
    <tze-code></tze-code>
  </p-tabPanel>
</p-tabView>
<tze-release-note [(release)]="releaseNote"></tze-release-note>
